import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'
import { PlayIcon } from '@heroicons/react/solid'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
//import PrismicLink from "../components/prismicLink"
import Similar from '../components/Similar'
import AdSlot from '../components/AdSlot'

const ReplayChannel = ({ data, location, pageContext }) => {
    if (!data) return null

    const document = data.prismicChannel?.data
    const settings = data.prismicGeneralSettings?.data

    const handleClick = () => {
        window.open(
            document.replay_channel_link?.url,
            'newWin',
            'toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, width=1200, height=665'
        )
    }

    const currentUrl = location.href ? location.href : ''

    return (
        <Layout>
            <Seo
                title={
                    document.replay_channel_seo_title?.text ||
                    document.replay_channel_title?.text
                }
                description={document.replay_channel_seo_description?.text}
                //ogImage={}
            />
            <div className="md:grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 pb-10 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="pb-4 border-b border-gray-300">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {prismicH.asText(
                                document.replay_channel_title?.richText
                            )}
                        </h1>
                    </div>

                    {/* Replay Channel Top Article Slot */}
                    <AdSlot
                        html={settings.replay_channel_top_article_slot?.text}
                    />

                    <div className="mt-4 pb-10 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={document.replay_channel_content?.richText}
                            //serializeHyperlink={PrismicLink}
                        />

                        {/* Replay Channel Before Button Slot */}
                        <AdSlot
                            html={settings.replay_channel_in_article_slot?.text}
                        />

                        <p>
                            La chaîne de télévision {document.name?.text}{' '}
                            propose des centaines d'émissions et de séries en
                            rediffusion sur internet. La rediffusion, aussi
                            appelée télévision de rattrapage ou replay, permet
                            de revoir ses vidéos préférées en streaming légal.
                            Que ce soit pour un documentaire, un JT ou un match
                            de sport, la rediffusion sur internet est souvent
                            gratuite.
                        </p>

                        {/* Replay Channel Before Button Slot */}
                        <AdSlot
                            html={
                                settings.replay_channel_before_button_slot?.text
                            }
                        />

                        <button
                            className="flex items-center justify-center w-full p-4 text-base sm:text-lg text-white rounded-md bg-blue-700 hover:bg-blue-900 appearance-none focus:outline-none transition"
                            onClick={handleClick}
                        >
                            <PlayIcon className="flex-shrink-0 mr-3 w-6 sm:w-8" />
                            <span>
                                Accéder à toutes les vidéos de{' '}
                                {document.name?.text} en replay
                            </span>
                        </button>

                        {/* Replay Channel After Button Slot */}
                        <AdSlot
                            html={
                                settings.replay_channel_after_button_slot?.text
                            }
                        />

                        <PrismicRichText
                            field={settings.replay_channel_foot_text?.richText}
                            //serializeHyperlink={PrismicLink}
                        />
                    </div>

                    <div
                        className="fb-comments"
                        data-href={currentUrl}
                        data-width="100%"
                        data-numposts="5"
                    />
                </div>
                <div className="pb-10">
                    {/* Replay Channel Top Right Slot */}
                    <AdSlot
                        html={settings.replay_channel_top_right_slot?.text}
                    />

                    <a
                        className="twitter-timeline"
                        data-height="350"
                        href={`https://twitter.com/${document.twitter_handle?.text}`}
                    >
                        Tweets by ${document.twitter_handle?.text}
                    </a>
                </div>
            </div>

            {/* Replay Channel Suggestions Top Slot */}
            <div className="flex justify-center">
                <AdSlot
                    html={settings.replay_channel_suggestions_top_slot?.text}
                />
            </div>

            <Similar
                channels={data.channels}
                type="replay"
                tops={pageContext.tops}
            />
        </Layout>
    )
}

export default ReplayChannel

export const query = graphql`
    query ReplayChannelQuery($uid: String!, $tops_uids: [String]) {
        prismicChannel(uid: { eq: $uid }) {
            data {
                replay_channel_title {
                    text
                    richText
                }
                replay_channel_seo_title {
                    text
                }
                replay_channel_seo_description {
                    text
                }
                replay_channel_content {
                    richText
                }
                replay_channel_link {
                    url
                }
                name {
                    text
                }
                twitter_handle {
                    text
                }
            }
        }
        prismicGeneralSettings {
            data {
                replay_channel_top_article_slot {
                    text
                }
                replay_channel_top_right_slot {
                    text
                }
                replay_channel_in_article_slot {
                    text
                }
                replay_channel_before_button_slot {
                    text
                }
                replay_channel_after_button_slot {
                    text
                }
                replay_channel_suggestions_top_slot {
                    text
                }
                replay_channel_foot_text {
                    richText
                }
            }
        }
        channels: allPrismicChannel(
            filter: { uid: { in: $tops_uids, ne: $uid } }
        ) {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
